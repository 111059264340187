
/* ==================== Variables ==================== */

$font-family-sans-serif: Arial, sans-serif;
$headings-font-family: 'RobotoCondensed', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;

$blue:#0072CE;
$dark-blue: #002D72;
$red: #b81e3a;
$green: #009639;
$orange: #D48035;
$yellow: #FFD100;
$turquois: #009CA6;
$body-color: #333333;
$headings-color: #616161;
$secondary: #adb5bd; 

$light-blue: #6cace4;
$dark-orange: #D48035;
$purple: #94308c;

$white:    #fff !default;
$gray-50: #fbfcfd !default;
$gray-100: #f8f9fa !default;
$gray-150: #f5f6f7 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-550: #91989e !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$theme-colors: (
  primary: $blue,
  danger: $red,
  success: $green,
  warning: $yellow,
  light: $gray-150,
  warning-dark: $orange
) !default;

$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

$input-color: #000000;
$input-placeholder-color: #a6a6a6;
$input-border-color: $secondary;
$input-focus-border-color: $blue;
$input-disabled-bg: #f4f4f4;
$input-bg-color: $white;

$switch-bg: $gray-200;
$switch-height: 1.425rem;
$switch-height-sm: 1.1625rem;
$switch-height-lg: 1.8rem;

$custom-select-focus-box-shadow:  0 0 0 .2rem rgba($blue, .25) !default;

$popover-max-width: 20rem;
$popover-border-radius: $border-radius;
$popover-border-color: $input-border-color;

$popover-header-bg: $gray-800;
$popover-header-color: $white;
$popover-body-color: $white;
$popover-bg: $gray-800;

$tooltip-max-width: 25rem;
$tooltip-bg: $gray-800;
$tooltip-opacity: 1;
$tooltip-padding-y: 1rem;
$tooltip-padding-x: 1rem 0;

$text-muted: $gray-500;



// ==================== Media variables ======================


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1281px,
  xxl: 1467px,
  xxxl: 1700px
) !default;

$screen: "only screen";
$sm-up: $screen;
$sm-only: "#{$screen} and (max-width: #{map-get($grid-breakpoints, md) - 1px})";

$md-up: "#{$screen} and (min-width:#{map-get($grid-breakpoints, md)})";
$md-only: "#{$screen} and (min-width:#{map-get($grid-breakpoints, md)}) and (max-width:#{map-get($grid-breakpoints, lg) - 1px})";

$sm-and-md-only: "#{$screen} and (min-width:#{0}) and (max-width:#{map-get($grid-breakpoints, lg) - 1px})";

$lg-up: "#{$screen} and (min-width:#{map-get($grid-breakpoints, lg)})";
$lg-only: "#{$screen} and (min-width:#{map-get($grid-breakpoints, lg)}) and (max-width:#{map-get($grid-breakpoints, xl) - 1px})";

$xl-up: "#{$screen} and (min-width:#{map-get($grid-breakpoints, xl)})";

$xxl-up: "#{$screen} and (min-width:#{map-get($grid-breakpoints, xxl)})";

$xxxl-up: "#{$screen} and (min-width:#{map-get($grid-breakpoints, xxxl)})";