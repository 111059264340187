
.material-icons, .material-icons-outlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.25rem;  /* Preferred icon size */
  vertical-align: -0.2857rem;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: 'liga';

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.icon-sm { 
  font-size: 0.8rem; 
  vertical-align: -.1rem;
}

.icon-lg { 
  font-size: 1.625rem; 
}

.icon-2x { 
  font-size: 2rem; 
}

.icon-3x { 
  font-size: 3rem; 
}

.icon-4x { 
  font-size: 4rem; 
}

.icon-5x { 
  font-size: 5rem; 
}

.icon-6x { 
  font-size: 6rem; 
}

.icon-7x { 
  font-size: 7rem; 
}

.icon-8x { 
  font-size: 8rem; 
}
