@use "sass:math";

@mixin dashed-border($dashlength, $spacelength, $borderwidth, $color, $position: top) {
  $per: percentage(math.div($dashlength, ($dashlength + $spacelength)));
  background-image: linear-gradient(to right, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
  background-position: 0 $position; 
  background-size: ($dashlength + $spacelength) $borderwidth;
  background-repeat: repeat-x;
}

@mixin dashed-border-vertical($dashlength, $spacelength, $borderwidth, $color) {
  $per: percentage(math.div($dashlength, ($dashlength + $spacelength)));
  background-image: linear-gradient(to bottom, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
  background-position: right;
  background-size: $borderwidth ($dashlength + $spacelength);
  background-repeat: repeat-y;
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: transparent){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount; 
  // text-align: justify;
  // margin-right: -1rem;
  // padding-right: 1rem;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: $bgColor;
  }
}
