@import './variables';
@import 'bootstrap/scss/bootstrap';
@import './assets/scss/mixins';
@import './assets/scss/icons';
@import './assets/scss/switch';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'nouislider/distribute/nouislider.min.css';
@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: 'RobotoCondensed';
  src: local('?'), url('assets/fonts/RobotoCondensed-Light.woff2') format('woff2'),
    url('assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: local('?'), url('assets/fonts/RobotoCondensed-Regular.woff2') format('woff2'),
    url('assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

//Trying out npm package recommened https://github.com/google/material-design-icons
// if this causes issues we should switch back to the old way
/* @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), 
       local('MaterialIcons-Regular'),
       url('assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
       url('assets/fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
       local('MaterialIcons-Outlined'),
       url('assets/fonts/MaterialIcons-Outlined.woff2') format('woff2'),
       url('assets/fonts/MaterialIcons-Outlined.woff') format('woff');
}
 */

/* ==================== Bootstrap Override ==================== */

.tooltip-inner {
  text-align: left;
  min-width: 15rem;
  height: 100%;

  label {
    color: $white;
    font-size: 1rem;
  }
}

.btn {
  &.btn-outline-secondary,
  &.btn-link-secondary {
    color: #6c757d;

    &:hover {
      color: #212529;
    }
  }

  &.btn-action {
    min-width: 8rem;
  }

  &.btn-tooltip {
    padding: 0;
  }
}

.btn-long {
  width: 160px;
}

label {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  color: $gray-550;
}

.custom-control {
  label {
    color: $body-color;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
  }

  &.custom-checkbox {
    .custom-control-label {
      &:before {
        background-color: #ffffff;
        border: 1px solid $input-border-color;
      }
    }

    .custom-control-input:disabled ~ .custom-control-label {
      &:before {
        background-color: $gray-200;
      }
    }

    .custom-control-input:focus ~ .custom-control-label {
      &:before {
        border-color: $blue;
        box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &:before {
        border-color: $blue;
        background-color: $blue;
      }
    }
  }
}

.custom-select {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  //is not auto generated (why?) and makes problems in Chrome on Windows
  -webkit-appearance: none;
  -moz-appearance: none;
}

table {
  &.table-md {
    th,
    td {
      padding: 0.5rem;
    }
  }
}

.form-group {
  margin-bottom: 1.75rem;

  &.with-error {
    margin-bottom: 0.125rem;
  }

  &.with-slider {
    margin-bottom: 4rem;
  }
}

.close {
  &:focus {
    outline: 0;
  }
}

.popover {
  .popover-header {
    border-bottom: none;
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    white-space: nowrap;
  }
}

.form-control-plaintext {
  &:focus,
  &:active {
    outline: 0;
  }
}

/* ==================== Globals ==================== */

html,
body {
  height: 100%;
}

html {
  &.font-large {
    font-size: 16px;
  }

  &.font-small {
    font-size: 14px;
  }
}

textarea {
  resize: none;
}

.table-header {
  margin-bottom: 1.5rem;

  .table-title {
    @extend .h3;
    margin-bottom: 0;
  }

  .list-inline {
    .list-inline-item {
      .btn {
        margin-top: -1px;
      }

      .input-group-append {
        .btn {
          margin-top: 0;
        }
      }
    }
  }
}

.table-filter {
  margin-bottom: 1.5rem;
}

.table-content {
  table {
    border-bottom: 1px solid $gray-300;
    margin-bottom: 0;

    thead {
      th {
        border: none;
        color: $gray-550;
        font-size: 0.875rem;
        border-color: $gray-300;
      }
    }

    tbody {
      tr {
        td {
          background-color: #ffffff;
          vertical-align: middle;
          transition: background-color 0.05s ease; // transition effect

          &.td-progress-bar-fix {
            line-height: 0.8625rem;

            .progress-inline {
              display: inline-block;
              width: 100%;

              height: 1.1875rem;
              line-height: 1.1875rem;
              padding-top: 0.75rem;
            }
          }
        }

        &:hover {
          td {
            background-color: #cce8ff;
          }
        }
      }
    }

    &.table-edit {
      tbody {
        tr {
          &:hover {
            td {
              cursor: pointer;
            }
          }
        }
      }

      th.action,
      td.action {
        width: 2.875rem;
        max-width: 2.875rem;
        padding: 0rem;

        &:last-child {
          text-align: right;
        }

        .btn-table-action {
          padding: 0.5rem 0.75rem;
          border-radius: 0;
          background: transparent;

          &.checked {
            color: $blue;
          }

          &:after {
            content: none;
            display: none;
          }

          &:focus,
          &:active {
            outline: 0;
            box-shadow: none;
          }

          &:not([readonly]):hover {
            background-color: $blue;
            color: #ffffff;
          }
        }

        & > .dropdown,
        & > .dropup {
          //TODO: temporary fix due to https://github.com/ng-bootstrap/ng-bootstrap/issues/3580
          position: static;

          &.show {
            .btn-table-action {
              background-color: $blue;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .table-spinner {
    & > .spinner-container {
      padding-top: 4.525rem;
      padding-bottom: 4.525rem;
      width: 100%;
      display: block;
      text-align: center;
      border-bottom: 1px solid $gray-300;
    }
  }

  .table-not-found {
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 100%;
    display: block;
    text-align: center;
    border-bottom: 1px solid $gray-300;
  }
}

.table-footer {
  padding-top: 1rem;
}

.row-text-link-underline {
  font-weight: bold;
  text-decoration: underline;
  color: $blue;
}

.edit-spinner {
  & > .spinner-container {
    padding-top: 4.525rem;
    padding-bottom: 4.525rem;
    width: 100%;
    display: block;
    text-align: center;
  }
}

.edit-not-found {
  padding-top: 7rem;
  padding-bottom: 5rem;
  width: 100%;
  display: block;
  text-align: center;
}

.btn-close {
  min-height: 2.375rem;
  float: none;
}

.content-card {
  border: none;
  background: none;

  &:not(:last-child):not(:only-child),
  &.with-border {
    &:after {
      content: '';
      display: block;
      margin-top: 0.625rem;
      height: 2px;
      width: 100%;
      @include dashed-border(2px, 6px, 2px, #bbbbbb);
    }

    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
  }

  &:not(:first-child):last-child {
    .card-body {
      padding-bottom: 0;
    }
  }

  .card-header {
    background: none;
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    .content-title {
      text-transform: uppercase;
      color: $orange;
      font-weight: bold;
    }
  }

  .card-body {
    background: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.filter-card {
  &:not(:last-child):not(:only-child) {
    margin-bottom: 1.5rem;
  }

  .card-header {
    background-color: $gray-100;
    border-bottom: none;

    .content-title {
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 0;
      color: #d48035;
      font-weight: bold;
    }
  }

  .card-body {
    background-color: $gray-100;
    padding-top: 0;
  }
}

.alert {
  &.alert-error {
    padding: 0.3125rem 0;
    font-size: 0.75rem;
    margin-bottom: 0;
    border: none;
    background: none;
    min-height: 1.75rem;
    margin-bottom: 0;

    & > span {
      margin-right: 0.25rem;
      color: $red;

      &.warning {
        color: $orange;
      }

      &.info {
        color: $gray-500;
      }
    }

    &.alert-double {
      min-height: 2.75rem;
    }
  }
}

.input-error {
  background-color: rgba(198, 20, 56, 0.22);
  border-color: #c61438;
  color: #b81e3a;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(198, 20, 56, 0.22);
  }
}

.input-warning {
  background-color: rgba($orange, 0.22);
  border-color: $orange;
  color: $orange;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($orange, 0.22);
  }
}

.form-control,
.custom-select {
  &.ng-invalid.ng-dirty,
  &.ng-invalid-alt.ng-dirty {
    @extend .input-error;
  }

  &.ng-warning {
    @extend .input-warning;
  }
}

input.form-control,
textarea.form-control,
.custom-select {
  &.form-control-readonly:disabled {
    padding-left: 0;
    border: none;
    background: none;
  }
}

.custom-select {
  &.form-control-readonly:disabled {
    color: $body-color;
  }
}

textarea {
  &.form-control.form-control-readonly:disabled {
    padding-left: 0.75rem;
    border-radius: 0;
    border-left: 1px solid $gray-400;
  }
}

.button-spinner-container {
  & + * {
    visibility: hidden;
  }
}

.button-confirmation-container {
  .confirmation-button + * {
    visibility: hidden;
  }
}

.avatar {
  display: inline-block;
  font-size: 0.75rem;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.95rem;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;

  &.avatar-green {
    background-color: #009639;
  }

  &.avatar-turquis {
    background-color: #009ca6;
  }

  &.avatar-yellow {
    background-color: #ffd100;
  }

  &.avatar-orange {
    background-color: #f2a900;
  }

  &.avatar-red {
    background-color: #e4002b;
  }

  &.avatar-brown {
    background-color: #8b5b29;
  }

  &.avatar-lila {
    background-color: #93328e;
  }

  &.avatar-blue {
    background-color: #6cace4;
  }

  &.avatar-gray {
    background-color: #666666;
  }
}

select {
  &:invalid {
    color: #a6a6a6;
  }
}

.hazard-icon {
  @extend .material-icons;
  padding: 0.25rem 0.5rem;
  text-align: center;
  color: #ffffff;
  border-radius: $border-radius;

  &.A,
  &.merged {
    &.negligible {
      background-color: #04ae41;

      &:before {
        content: 'crop_free';
      }
    }

    &.very_low {
      background-color: #72d02d;

      &:before {
        content: 'center_focus_weak';
      }
    }

    &.low {
      background-color: #b4d416;

      &:before {
        content: 'center_focus_strong';
      }
    }

    &.significant {
      background-color: #f5c000;

      &:before {
        content: 'flag';
      }
    }

    &.high {
      background-color: #ff9024;

      &:before {
        content: 'priority_high';
      }
    }

    &.very_high {
      background-color: #ff6e11;

      &:before {
        content: 'report_problem';
      }
    }

    &.extreme {
      background-color: #fb3f17;

      &:before {
        content: 'whatshot';
      }
    }

    &.unacceptable {
      background-color: #df0429;

      &:before {
        content: 'not_interested';
      }
    }
  }

  &.B,
  &.merged {
    &.very_low {
      background-color: #72d02d;

      &:before {
        content: 'center_focus_weak';
      }
    }

    &.low {
      background-color: #b4d416;

      &:before {
        content: 'center_focus_strong';
      }
    }

    &.medium {
      background-color: #ffb624;

      &:before {
        content: 'broken_image';
      }
    }

    &.high {
      background-color: #ff9024;

      &:before {
        content: 'priority_high';
      }
    }

    &.very_high {
      background-color: #ff6e11;

      &:before {
        content: 'report_problem';
      }
    }
  }

  &.C,
  &.merged {
    &.eliminated {
      background-color: #009736;

      &:before {
        content: 'done';
      }
    }

    &.unacceptable {
      background-color: #df0429;

      &:before {
        content: 'not_interested';
      }
    }
  }
}

.ng-select {
  .ng-select-container {
    min-height: calc(2.25rem + 2px);
    border-radius: $border-radius;
    border-color: $input-border-color;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      box-shadow: none;
    }
  }

  &.ng-select-readonly {
    &.ng-select-disabled {
      .ng-select-container {
        border: 0;
        background: none;

        .ng-arrow-wrapper {
          display: none;
        }
      }
    }

    &.ng-select-single {
      &.ng-select-disabled {
        .ng-select-container {
          .ng-value-container {
            padding-left: 0;

            .ng-arrow-wrapper {
              display: none;
            }
          }
        }
      }
    }
  }

  &.ng-select-focused,
  &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container {
      border-color: map-get($theme-colors, 'primary');
      box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, 'primary'), 0.25);

      &:hover {
        box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, 'primary'), 0.25);
      }
    }
  }

  &.ng-invalid.ng-dirty {
    .ng-select-container {
      @extend .input-error;
    }

    &.ng-select-focused {
      .ng-select-container {
        box-shadow: 0 0 0 0.2rem rgba(198, 20, 56, 0.22);
      }
    }

    &.ng-select-focused:not(.ng-select-opened) {
      .ng-select-container {
        border-color: #c61438;
      }
    }
  }

  &.ng-warning {
    .ng-select-container {
      @extend .input-warning;
    }

    &.ng-select-focused {
      .ng-select-container {
        box-shadow: 0 0 0 0.2rem rgba($orange, 0.22);
      }
    }

    &.ng-select-focused:not(.ng-select-opened) {
      .ng-select-container {
        border-color: $orange;
      }
    }
  }

  &.ng-select-single {
    .ng-clear-wrapper {
      padding-top: 3px;
    }

    .ng-select-container {
      height: calc(2.25rem + 2px);

      .ng-value-container {
        padding-left: 0.75rem;

        .ng-input {
          padding-left: 11px;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-clear-wrapper {
      display: none;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 0.4375rem;

        .ng-value {
          background-color: map-get($theme-colors, 'primary');
          overflow: hidden;
          border: none;
          color: $white;

          .ng-value-icon {
            padding: 0.125rem 0.3125rem;
            background-color: darken(map-get($theme-colors, 'primary'), 10%);

            &.left {
              border-right: none;
            }

            &:hover {
              background-color: darken(map-get($theme-colors, 'primary'), 20%);
            }
          }

          .ng-value-label {
            padding: 0.125rem 0.3125rem;
            padding-right: 0.625rem;
          }
        }
      }

      &.ng-has-value {
        .ng-value-container {
          padding-top: 6px;
        }
      }
    }

    &.ng-select-disabled {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            border-color: $gray-600;

            .ng-value-label {
              background-color: $gray-600;
            }
          }
        }
      }
    }
  }
}

.ng-dropdown-panel {
  &.form-control {
    height: auto;
  }

  &.ng-select-bottom {
    border-top-color: $input-border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .ng-dropdown-panel-items {
      .ng-option:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  &.ng-select-top {
    border-bottom-color: $input-border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .ng-dropdown-panel-items {
      .ng-option:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
  }

  .ng-dropdown-panel-items {
    margin-bottom: 0;
    max-height: 15rem;

    .ng-option {
      padding: 0.375rem 0.625rem;

      &.ng-option-marked {
        background-color: #cce8ff;
      }
    }
  }
}

.input-group > .form-control {
  &.ng-select {
    padding: 0;
    border: none;
    min-width: 11rem;
    max-width: 11rem;

    .ng-select-container {
      border-radius: 0;
      width: 100%;
    }

    .ng-dropdown-panel {
      padding: 0;
      width: 100%;
    }

    &.width-2x {
      min-width: 22rem;
      max-width: 22rem;
    }
  }

  &:not(.ng-select) {
    &.width-2x {
      min-width: 22rem;
      max-width: 22rem;
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    cursor: pointer;

    &:hover {
      background-color: $gray-300;
    }

    &:focus,
    &:active {
      outline: 0;
      background-color: $gray-550;
    }

    &.disabled {
      cursor: default;
      color: $gray-500;
      background-color: transparent;
    }
  }
}

.more-actions {
  .btn {
    &:after {
      content: '';
      display: none;
    }
  }
}

/* Machine Assessment Menu icons must be defined here because dropdown is rendered in body not in component */
.assessment-menu,
.checklist-menu {
  .icon {
    @extend .material-icons;
    vertical-align: -0.25rem;
    margin-right: 0.5rem;

    &.icon-comply {
      &:after {
        content: 'check_circle';
        color: $green;
      }
    }

    &.icon-not_applicable {
      &:after {
        content: 'remove_circle';
        color: $gray-800;
      }
    }

    &.icon-not_relevant {
      &:after {
        content: 'visibility_off';
        color: $gray-600;
      }
    }
  }
}

.tooltip-content {
  p {
    color: $gray-100;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
  }
}

.badge {
  &.badge-turquois {
    background-color: $turquois;
    color: $white;
  }

  &.badge-orange {
    background-color: $orange;
    color: $white;
  }
}

/* Styling of popup cannot be in component's css, because popup is not rendered inside component */
.selection-grid {
  .dx-header-row {
    font-size: 0.875rem;
    font-weight: 700;
    color: $gray-550;
  }
  .dx-datagrid-filter-row {
    .dx-editor-with-menu {
      border-top: 1px solid $gray-400;
      border-left: 1px solid $gray-400;
    }
    td:last-child .dx-editor-with-menu {
      border-right: 1px solid $gray-400;
    }
  }
}

.pointer {
  cursor: pointer;
}
